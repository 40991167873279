import React from 'react'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick";
import Wrapper from './Wrapper';
import { useRef } from 'react';

function Success() {
  const slider = React.useRef(null);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 1267,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
        
      };
  return (
    <Wrapper className='py-[103px] relative'>

        <h6 id='success' className='text-[45px] text-white mx-auto text-center mb-6 font-black block'>SUCCESS IS COOKING</h6>
        <button className='w-[48px] flex flex-col items-center justify-center  h-[48px] bg-[#7EC1FF] absolute rounded-full left-[0] top-[50%] z-50' onClick={() => slider?.current?.slickPrev()}>    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      fill="none"
      viewBox="0 0 21 22"
    >
      <mask
        id="mask0_29_81"
        style={{ maskType: "alpha" }}
        width="21"
        height="22"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#D9D9D9" d="M0 0.5H21V21.5H0z"></path>
      </mask>
      <g mask="url(#mask0_29_81)">
        <path
          fill="#fff"
          d="M14 19.75L5.25 11 14 2.25l1.553 1.553L8.356 11l7.197 7.197L14 19.75z"
        ></path>
      </g>
    </svg></button>
<button className='w-[48px] flex flex-col items-center justify-center  h-[48px] bg-[#7EC1FF] rounded-full absolute right-[0] top-[50%] z-50' onClick={() => slider?.current?.slickNext()}>    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
    >
      <mask
        id="mask0_29_84"
        style={{ maskType: "alpha" }}
        width="21"
        height="21"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#D9D9D9"
          d="M21 21H42V42H21z"
          transform="rotate(-180 21 21)"
        ></path>
      </mask>
      <g mask="url(#mask0_29_84)">
        <path
          fill="#fff"
          d="M7 1.75l8.75 8.75L7 19.25l-1.553-1.553 7.197-7.197-7.197-7.197L7 1.75z"
        ></path>
      </g>
    </svg></button>
        <p className='text-center max-w-md mx-auto text-[#aaa] mb-6'>Grab a glimpse of the spectacular success our members are having daily.</p>
        {/* <button onClick={() => slider?.current?.slickPrev()}>Prev</button>
<button onClick={() => slider?.current?.slickNext()}>Next</button> */}
         <Slider ref={slider} {...settings}>
            <img className='max-w-[308px]' src="/images/review-1.png"/>
            <img className='max-w-[308px]' src="/images/review-2.png"/>
            <img className='max-w-[308px]' src="/images/review-3.png"/>
            <img className='max-w-[308px]' src="/images/review-4.png"/>
            <img className='max-w-[308px]' src="/images/review-5.png"/>
            <img className='max-w-[308px]' src="/images/review-6.png"/>
        </Slider>
        <div className='flex flex-row justify-between items-center mt-24'>
            <div className='h-[1px] bg-white/30 w-[35%]'></div>
            <a href="https://whop.com/topcooks/" className=' flex items-center justify-center  rounded-full text-[18px] text-white w-[208px] h-[54px] bg-gradient-to-r from-[#5FA6E7] to-[#2889E1] ..."></ '>Start Cooking</a>
            <div className='h-[1px] bg-white/30 w-[35%]'></div>
        </div>
    </Wrapper>
  )
}

export default Success