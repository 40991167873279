import React from 'react'
import Wrapper from './Wrapper'

function Features() {
  return (
    <Wrapper  className="py-[50px]">
        <h6 id="features" className='text-[28px] text-white font-black text-center mb-[50px]'>TOP FEATURES</h6>
        <div className='flex flex-row items-center gap-[32px] flex-wrap justify-center'>
          <div className='bg-[#181B1F] rounded-xl p-[20px] flex flex-col items-start gap-y-4 w-full md:max-w-[287px]'>
            <div className='flex flex-row items-center gap-x-6'>
              <img src="/images/guides.png"/>
              <p className='text-white font-bold text-[15px]'>Consignment Stores & Guides</p>
            </div>
            <p className='text-[13px] text-[#aaa]'>Provide sneaker stores with your inventory and get paid out when they sell for $100- $200 OVER market!</p>
          </div>
          <div className='bg-[#181B1F] rounded-xl p-[20px] flex flex-col items-start gap-y-4 w-full md:max-w-[287px]'>
            <div className='flex flex-row items-center gap-x-6'>
              <img src="/images/monitor.png"/>
              <p className='text-white font-bold text-[15px]'>Sneaker & Retail Monitors</p>
            </div>
            <p className='text-[13px] text-[#aaa]'>Use our lightning fast monitors to get notified before the rest whenever something profitable drops!</p>
          </div>
          <div className='bg-[#181B1F] rounded-xl p-[20px] flex flex-col items-start gap-y-4 w-full md:w-full md:max-w-[287px]'>
            <div className='flex flex-row items-center gap-x-6'>
              <img src="/images/sales_monitor.png"/>
              <p className='text-white font-bold text-[15px]'>Nike Instore Checker & Sales Monitor</p>
            </div>
            <p className='text-[13px] text-[#aaa]'>Check the current inventory of your local stores, be the first to know when they get profitable items in stock!</p>
          </div>
          <div className='bg-[#181B1F] rounded-xl p-[20px] flex flex-col items-start gap-y-4 w-full md:max-w-[287px]'>
            <div className='flex flex-row items-center gap-x-6'>
              <img src="/images/cart.png"/>
              <p className='text-white font-bold text-[15px]'>Automated Checkout</p>
            </div>
            <p className='text-[13px] text-[#aaa]'>Our staff are the best botters in the game. Let us cop profitable items for you and get them sent directly to you </p>
          </div>
        </div>
    </Wrapper>
  )
}

export default Features