import React from 'react'
import Wrapper from './Wrapper'

function Footer() {
  return (
    <div className='py-[74px] bg-[#06090D]'>
        <Wrapper>
            <div className='flex flex-col md:flex-row  items-center justify-between'>
                <div className='flex flex-col items-center md:items-start gap-y-[34px]'>
                <img
                    src="/images/logo.png"
                    className="h-24"
                    alt="TopCooks Logo"
                />
                <p className='text-[#808080] text-[16px] max-w-md mx-auto md:mx-0 text-center md:text-left'>The only group you need.</p>
                <p className='text-[#545454] text-[14px] max-w-md mx-auto md:mx-0 text-center md:text-left'>©2023, All rights reserved.</p>
                </div>
                <div className='flex flex-col items-center md:items-start lg:flex-row gap-y-4 md:gap-y-0 md:gap-x-6 mt-6'>
                    <a className='text-white text-[16px]' href="#features">Top Features</a>
                    <a className='text-white text-[16px]' href="#success">Success</a>
                    <a className='text-white text-[16px]' target='_blank' href="https://discord.gg/fEMFcB2ZnE">Get Started</a>
                </div>
                <div className='flex flex-col items-center md:mt-0 mt-6 md:items-end '>
                    <a href="https://www.instagram.com/topcookscg?igsh=MWF6eTlweHdiMTk2cQ%3D%3D&utm_source=qr"><svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 0.625H7C3.20305 0.625 0.125 3.70305 0.125 7.5V18.5C0.125 22.2969 3.20305 25.375 7 25.375H18C21.7969 25.375 24.875 22.2969 24.875 18.5V7.5C24.875 3.70305 21.7969 0.625 18 0.625ZM22.4688 18.5C22.4612 20.9648 20.4648 22.9612 18 22.9688H7C4.53511 22.9612 2.5388 20.9648 2.53125 18.5V7.5C2.5388 5.03511 4.53511 3.0388 7 3.03125H18C20.4648 3.0388 22.4612 5.03511 22.4688 7.5V18.5ZM19.0312 7.84375C19.7907 7.84375 20.4062 7.22814 20.4062 6.46875C20.4062 5.70936 19.7907 5.09375 19.0312 5.09375C18.2718 5.09375 17.6562 5.70936 17.6562 6.46875C17.6562 7.22814 18.2718 7.84375 19.0312 7.84375ZM12.5 6.8125C9.08274 6.8125 6.3125 9.58274 6.3125 13C6.3125 16.4173 9.08274 19.1875 12.5 19.1875C15.9173 19.1875 18.6875 16.4173 18.6875 13C18.6912 11.3578 18.0404 9.78191 16.8792 8.62073C15.7181 7.45956 14.1422 6.80884 12.5 6.8125ZM8.71875 13C8.71875 15.0884 10.4116 16.7812 12.5 16.7812C14.5884 16.7812 16.2812 15.0884 16.2812 13C16.2812 10.9116 14.5884 9.21875 12.5 9.21875C10.4116 9.21875 8.71875 10.9116 8.71875 13Z" fill="white"/>
                    </svg>
                    </a>
                    <div className='flex flex-row items-center gap-x-4 mt-[120px]'>
                        <a className='underline cursor-pointer text-[#545454]'>Privacy Policy</a>
                        <a className='underline cursor-pointer text-[#545454]'>Terms of Service</a>
                    </div>
                </div>
            </div>
        </Wrapper>
        
    </div>
  )
}

export default Footer