import React from 'react'
import Wrapper from './Wrapper'
import { useState } from 'react'


function Navbar() {
  const [show, setShow] = useState(false)
  return (
    <nav className="bg-[#06090D] dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-white/10 dark:border-white/10">
        <Wrapper>
  <div className="max-w-screen-xl py-8 px-4 flex flex-wrap items-center justify-between mx-auto ">
      <img
        src="/images/logo.png"
        className="h-24"
        alt="TopCooks Logo"
      />
    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
        <div className='flex flex-row items-center gap-x-6'>
            <a href="https://www.instagram.com/topcookscg?igsh=MWF6eTlweHdiMTk2cQ%3D%3D&utm_source=qr"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 3H8C5.23858 3 3 5.23858 3 8V16C3 18.7614 5.23858 21 8 21H16C18.7614 21 21 18.7614 21 16V8C21 5.23858 18.7614 3 16 3ZM19.25 16C19.2445 17.7926 17.7926 19.2445 16 19.25H8C6.20735 19.2445 4.75549 17.7926 4.75 16V8C4.75549 6.20735 6.20735 4.75549 8 4.75H16C17.7926 4.75549 19.2445 6.20735 19.25 8V16ZM16.75 8.25C17.3023 8.25 17.75 7.80228 17.75 7.25C17.75 6.69772 17.3023 6.25 16.75 6.25C16.1977 6.25 15.75 6.69772 15.75 7.25C15.75 7.80228 16.1977 8.25 16.75 8.25ZM12 7.5C9.51472 7.5 7.5 9.51472 7.5 12C7.5 14.4853 9.51472 16.5 12 16.5C14.4853 16.5 16.5 14.4853 16.5 12C16.5027 10.8057 16.0294 9.65957 15.1849 8.81508C14.3404 7.97059 13.1943 7.49734 12 7.5ZM9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25C10.4812 9.25 9.25 10.4812 9.25 12Z" fill="white"/>
</svg>
</a>
        
      <a
      href="https://whop.com/topcooks/"
        type="button"
        className="text-black bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm px-6 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      >
        Get started
      </a>
      </div>
      <button
        data-collapse-toggle="navbar-sticky"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden  focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        aria-controls="navbar-sticky"
        aria-expanded="false"
      >
        <span className="sr-only">Open main menu</span>
        <svg
        onClick={() => setShow(!show)}
          className="w-5 h-5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 17 14"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M1 1h15M1 7h15M1 13h15"
          />
        </svg>
      </button>
    </div>
    <div
      className={`items-center justify-between  w-full md:flex md:w-auto md:order-1 ${show === true ? 'block' : 'hidden'}`}
      id="navbar-sticky"
    >
      <ul className="flex flex-col p-4 py-4 md:p-0 mt-4 font-medium border border-white/10 rounded-md md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
          <a
            href="#features"
            className="block py-2 px-3 text-white  rounded md:bg-transparent md:text-white md:hover:text-[#7DC1FF] md:p-0 md:dark:hover:text-[#7DC1FF] md:dark:text-white"
            aria-current="page"
          >
            Top Features
          </a>
        </li>
        <li>
          <a
            href="#success"
            className="block py-2 px-3 text-white rounded  md:hover:bg-transparent md:hover:text-[#7DC1FF] md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
          >
            Success
          </a>
        </li>
        <li>
          <a
            href="https://whop.com/topcooks/"
            className="block py-2 px-3 text-white rounded  md:hover:bg-transparent md:hover:text-[#7DC1FF] md:p-0 md:dark:hover:text-[#7DC1FF] dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
          >
            Get Started
          </a>
        </li>
      </ul>
    </div>
  </div>
  </Wrapper>
</nav>

  )
}

export default Navbar