import React from 'react'
import Wrapper from './Wrapper'

function Header() {
  return (
    
    <div className='relative mx-auto w-full overflow-hidden'>
        <img className='absolute bottom-[-70%] right-[-15%] z-0' src="/images/blur-bg.png" />
    
    <Wrapper className="pt-[calc(72px+96px)] overflow-hidden relative ">
        <div className='flex items-center justify-center flex-col lg:flex-row lg:justify-between w-full relative z-1'>
            <div className='flex flex-col items-start gap-y-[34px]'>
                <h1 className='text-[40px] md:text-[55px] text-white text-center mx-auto lg:m-0 lg:text-left max-w-lg font-black leading-tight'> <span className='bg-gradient-to-r from-[#7DC1FF] to-[#2E7BC2] inline-block text-transparent bg-clip-text'> COOK</span> YOUR <br /> WAY TO THE <br /> TOP</h1>
                <p className='text-[#aaa] text-[17px] text-center lg:text-left max-w-lg '>Top Cooks will provide you with many tools to instantly scale your reselling business. We help resellers secure sneakers and more.</p>
                <div className='flex mx-auto md:mx-0 flex-col gap-y-[16px] md:flex-row items-center gap-x-[20px]'>
                    <a href="https://whop.com/topcooks/" className=' flex items-center justify-center  rounded-full text-[18px] text-white w-[208px] h-[54px] bg-gradient-to-r from-[#5FA6E7] to-[#2889E1] ..."></ '>Join Now</a>
                    <a href="https://discord.gg/fEMFcB2ZnE" className=' flex items-center justify-center border border-white rounded-full text-[18px] text-white w-[208px] h-[54px] '>Explore More</a>
                </div>
                <div className='flex flex-row mx-auto lg:mx-0 lg:my-0 my-12 items-center gap-x-[20px]'>
                    <div className='flex flex-col items-start '>
                        <div className='flex flex-row'>
                        <svg className='mr-3' width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4999 28.4166C22.6338 28.4166 28.4166 22.6339 28.4166 15.5C28.4166 8.36611 22.6338 2.58331 15.4999 2.58331C8.36604 2.58331 2.58325 8.36611 2.58325 15.5C2.58325 22.6339 8.36604 28.4166 15.4999 28.4166Z" stroke="#7DC1FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.625 15.5L14.2083 18.0834L19.375 12.9167" stroke="#7DC1FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className='text-white text-[18px] font-bold'>$20M+</p>
                        </div>
                        <p className='text-[#7B7B7B] text-[12px] ml-12'>SECURED IN PROFIT</p>
                    </div>
                    <div className='flex flex-col items-start '>
                        <div className='flex flex-row'>
                        <svg className='mr-3' width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.4999 28.4166C22.6338 28.4166 28.4166 22.6339 28.4166 15.5C28.4166 8.36611 22.6338 2.58331 15.4999 2.58331C8.36604 2.58331 2.58325 8.36611 2.58325 15.5C2.58325 22.6339 8.36604 28.4166 15.4999 28.4166Z" stroke="#7DC1FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.625 15.5L14.2083 18.0834L19.375 12.9167" stroke="#7DC1FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <p className='text-white text-[18px] font-bold'>300+</p>
                        </div>
                        <p className='text-[#7B7B7B] text-[12px] ml-10'>ACTIVE MEMBERS</p>
                    </div>
                </div>
            </div>
            <img className='max-w-[60%]' src="/images/phones.png"/>
        </div>
    </Wrapper>
    </div>
  )
}

export default Header