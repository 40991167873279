import React from 'react'
import Wrapper from './Wrapper'

function Help() {
  return (
    <div id="help"  className='bg-gradient-to-r relative py-[60px] from-[#5FA6E7] to-[#2889E1]'>
        <img  className='hidden lg:block lg:absolute top-[-15%] right-[0%]' src="/images/help.png"/>
        <Wrapper>
            <div className='flex flex-col items-start gap-y-8'>
                <img src="/images/help-text.png"/>
                <p className='text-white text-left max-w-xl text-[17px]'>Join our FREE waitlist discord server and see the daily success that comes from being a Top Cooks paid member.</p>
                <a href="https://discord.gg/fEMFcB2ZnE" className=' flex items-center justify-center bg-black rounded-full text-[18px] text-white w-[208px] h-[54px] '>Join Our Free Server </a>
            </div>
        </Wrapper>
    </div>
  )
}

export default Help