import React from 'react'

function Wrapper({children, className}) {
  return (
    <div className={`max-w-[1280px] mx-auto px-4 ${className}`}>
        {children}
    </div>
  )
}

export default Wrapper